/**
 * Get a delay loaded instance of the cropperJS library
 *
 * @param {HTMLImageElement} image
 * @param {Object} options A CropperJS options object
 */
export async function getCropper(image, options) {
  const { default: Cropper } = await import('cropperjs');
  return new Promise(resolve => {
    const cropper = new Cropper(image, {
      ...options,
      ready: () => resolve(cropper)
    });
  });
}
