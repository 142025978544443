var angular = require('angular');
var { getCropper } = require('../../util/cropper.js');
var _ = require('underscore');

const UrlService = require('../../util/url_service.js').default;

var ENTER_KEYCODE = 13;
var LOADING_CLASS = 'visual-searches--loading';
var URL_BASE_REGEX = /(fonts\/vs\/[^\/]*)(\/)*(.*)*/; // eslint-disable-line no-useless-escape
var USER_TEXT_PARAM = 'text';
var DEFAULT_EXAMPLE_TEXT = 'The quick brown fox jumps over the lazy dog';

var STEPS = ['upload', 'select', 'confirm', 'results'];
var SCROLL_DURATION = 1000;
/**
 * Controller for the Version 2 Visual Search page.
 * @ngInject
 */
function VisualSearchesController($document,
                                  $http,
                                  $q,
                                  $scope,
                                  $window,
                                  DataService,
                                  DialogService,
                                  FilterVariationsService,
                                  I18nService,
                                  NewrelicService,
                                  ScrollService,
                                  UserPrefsService,
                                  VisualSearchService) {
  var self = this;
  self.$document = $document;
  self.$http = $http;
  self.$q = $q;
  self.$scope = $scope;
  self.$window = $window;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FilterVariationsService = FilterVariationsService;
  self.I18nService = I18nService;
  self.NewrelicService = NewrelicService;
  self.ScrollService = ScrollService;
  self.VisualSearchService = VisualSearchService;
  self.UserPrefsService = UserPrefsService;
  self.filterValues = [];
  self._init();
}

VisualSearchesController.prototype.filterFontsByRequiredActions = function(toggles) {
  var requiredActions = this.FilterVariationsService.filterRequiredActionsByToggles(this.fonts, toggles);

  this.preferredAction = requiredActions.preferred;
  this.filteredFonts = this.FilterVariationsService.filterFontsByRequiredActions(this.fonts, requiredActions.filters);
};

VisualSearchesController.prototype.showPaidFontsSelected = function() {
  return this.showPaidFonts;
};

/**
 * Shows the tips modal
 */
VisualSearchesController.prototype.showTipsModal = function() {
  var self = this;

  self.DialogService.show('/angular_templates/dialogs/visual_search_tips.html', {
    closeOnDrag: true
  }).then(function() {
    self.NewrelicService.addPageAction('typekit.render.visual-search.search-tips-modal');
  });
};

/**
 * Handle the keyup event on the example text input.
 * @private
 */
VisualSearchesController.prototype.handleExampleTextKeyup = function($event) {
  if ($event.key === 'Enter' || $event.keyCode === ENTER_KEYCODE) {
    this.handleNextButtonClick();
  } else {
    this._userEnteredText = $event.target.value;
    this._updateExampleText($event.target.value);
  }
};

/**
 * Handles clicks on the next button.
 */
VisualSearchesController.prototype.handleNextButtonClick = function() {
  this.loadStep(this._getNextStep());
};

/**
 * Handles clicks on the start over button.
 */
VisualSearchesController.prototype.handleStartOverClick = function() {
  this._setCurrentStep(STEPS[0], true);
};

/**
 * Handles clicks on the previous button.
 */
VisualSearchesController.prototype.handlePreviousClick = function() {
  var stepIndex = STEPS.indexOf(this._currentStep);
  var previousStep = STEPS[stepIndex - 1];
  this._setCurrentStep(previousStep, true);
};

/**
 * Toggles returns class for toggling family card list and grid view
 * @returns {String}
 */
VisualSearchesController.prototype.fontVariationCardsClass = function() {
  return this.viewType === 'grid' ? 'spectrum-grid-row font-variations-grid-view' : '';
};

/**
 * Get the value that should be displayed in the example text area.
 * @returns {String}
 */
VisualSearchesController.prototype.getExampleTextValue = function() {
  return this._userEnteredText || this.extractedText;
};

/**
 * Handles clicks on the step buttons.
 * @param {Number} step
 */
VisualSearchesController.prototype.loadStep = function(step) {
  var self = this;
  if (self._currentStep == step) {
    return;
  }

  // If the current step is the first step, update the results first before
  // updating the step.
  if (self._currentStep == STEPS[1]) {
    self._updateResultsForCoordinates().then(function() {
      self._setCurrentStep(step, true);
      self._updateExampleText(self._userEnteredText || self.extractedText);
      self._scrollToTop();
    });
  } else {
    self._setCurrentStep(step, true);
    self._scrollToTop();
  }
};

/**
 * Is the passed in step the current step?
 *
 * @param {Number} step
 * @returns {Boolean}
 */
VisualSearchesController.prototype.isCurrentStep = function(step) {
  return this._currentStep == step;
};

/**
 * Should the confirmation image be visible? The confirmation image will show up
 * in multiple steps.
 *
 * @returns {Boolean}
 */
VisualSearchesController.prototype.isConfirmationImageVisible = function() {
  switch (this._currentStep) {
  case STEPS[0]:
    return false;
  case STEPS[1]:
    return false;
  default:
    return true;
  }
};

/**
 * Is the list of fonts currently empty?
 * @returns {Boolean}
 */
VisualSearchesController.prototype.isFontListEmpty = function() {
  return this.fonts.length === 0;
};

/**
 * Is the list of filtered fonts currently empty?
 * @returns {Boolean}
 */
VisualSearchesController.prototype.isFilteredFontListEmpty = function() {
  return this.filteredFonts.length === 0;
};

/**
 * Returns the filtered number of fonts and translated pluralized string for display in search results
 * @returns {String}
 */
VisualSearchesController.prototype.getSimilarFontCountForDisplay = function() {
  if (this.filteredFonts.length > 1) {
    return this.I18nService.getMessage(this._i18n, 'neue.visual_search.results_page.similar_fonts_plural', {
      num_fonts: this.filteredFonts.length
    });
  }
  if (this.filteredFonts.length === 1) {
    return this.I18nService.getMessage(this._i18n, 'neue.visual_search.results_page.similar_font_singular', {
      num_fonts: 1
    });
  }
  return this.I18nService.getMessage(this._i18n, 'neue.visual_search.results_page.similar_fonts_plural', {
    num_fonts: 0
  });
};

/**
 * Returns the cropper coordinates that should be sent to the server.
 *
 * @private
 * @returns {{}}
 */
VisualSearchesController.prototype._getCropperCoordinates = function() {
  var data = this.cropper.getData();
  return {
    x_min: data.x,
    y_min: data.y,
    x_max: data.x + data.width,
    y_max: data.y + data.height
  };
};

/**
 * Returns the initial data for the cropper.
 *
 * @private
 * @param {Object} data
 * @returns {Object}
 */
VisualSearchesController.prototype._getCropperData = function(data) {
  // If the data includes a previous set of coordinates, use them to initialize the cropper.
  if (data.coordinates && Object.keys(data.coordinates).length != 0) {
    return {
      x: data.coordinates.x_min,
      y: data.coordinates.y_min,
      width: data.coordinates.x_max - data.coordinates.x_min,
      height: data.coordinates.y_max - data.coordinates.y_min
    };
  }

  // Otherwise, pick a region from the list of detected regions (if there are any).
  if (data.detectedRegion && Object.keys(data.detectedRegion).length != 0) {
    var region = data.detectedRegion;
    return {
      x: region.x_min,
      y: region.y_min,
      width: region.x_max - region.x_min,
      height: region.y_max - region.y_min
    };
  }

  // Fallback on an empty object if nothing was detected.
  return {};
};

/**
 * Returns next step after the current one. If the current step is the last one,
 * it will loop back around to the first step.
 *
 * @private
 * @returns {String}
 */
VisualSearchesController.prototype._getNextStep = function() {
  var currentStepNumber = STEPS.indexOf(this._currentStep);
  if ((currentStepNumber + 1) < STEPS.length) {
    return STEPS[currentStepNumber + 1];
  }

  return STEPS[0];
};

/**
 * Returns the canvas element where the confirmation image will be drawn.
 * @returns {Element}
 */
VisualSearchesController.prototype._getPlaceholderConfirmationCanvas = function() {
  return this.$document[0].querySelector(
    '.visual-searches__confirm-image .visual-searches__confirmation-canvas-placeholder');
};

/**
 * Returns the step for the current url. Falls back on the first step if no step
 * name was found.
 *
 * @private
 * @returns {String}
 */
VisualSearchesController.prototype._getStepFromUrl = function() {
  var matches = URL_BASE_REGEX.exec(this.$window.location.pathname);

  // If it matches, the step name will be the last part of the url.
  if (matches[matches.length - 1]) {
    return matches[matches.length - 1];
  }

  return STEPS[0];
};

/**
 * Returns the url for the step.
 *
 * @private
 * @param {Number} step
 * @returns {String}
 */
VisualSearchesController.prototype._getUrlForStep = function(step) {
  var urlBase = URL_BASE_REGEX.exec(this.$window.location.pathname)[1];
  return '/' + urlBase + '/' + step;
};

/**
 * Initialize all of the page's components.
 * @private
 */
VisualSearchesController.prototype._init = function() {
  var self = this;
  self.viewType = 'list';

  self._confirmationCanvas = self._getPlaceholderConfirmationCanvas();

  // Start by preloading all of the data required to display the page. Some of the
  // data may not be used right away, but load it anyway so that the page can
  // start on any step.
  self._preloadData().then(function(resolutions) {
    // Once the data is loaded, preload the image and create a cropper for it.
    var data = resolutions[1];
    if (self._currentStep != STEPS[0]) {
      self._preloadImage().then(function() {
        return self._initCropper(self._getCropperData(data));

      // When the cropper has completely loaded, use it to draw the confirmation
      // image. This will be needed if the user starts on a step that assumes
      // that the image has already been cropped. For example, if the user
      // navigates away from the page after cropping the image and then hits the
      // back button to come back to the page.
      }).then(function() {
        self._showCroppedImage();
      });
    }
  });


  // When the url changes, make sure that the page state is updated to match.
  self.$window.onpopstate = function() {
    self.$scope.$apply(function() {
      self._setCurrentStep(self._getStepFromUrl());
    });
  };
};

/**
 * Initialize the cropper control.
 *
 * @param {Object} data
 * @private
 */
VisualSearchesController.prototype._initCropper = function(data) {
  var self = this;
  self._setMaxContainerDimensions();

  return getCropper(self.image, {
    background: false,
    data: data,
    minCropBoxHeight: 10,
    minCropBoxWidth: 10,
    minContainerHeight: 10,
    minContainerWidth: 10,
    dragMode: 'none',
    responsive: true,
    zoomable: false,

    // Without this, cropperjs sends a timestamp param that doesn't work with presigned URLs
    checkCrossOrigin: false,

    // Required for setData method to work
    viewMode: 1
  }).then(cropper => {
    self.cropper = cropper;
  });
};

/**
 * Wait until the image is loaded on the page before drawing the cropper.
 * @private
 */
VisualSearchesController.prototype._preloadImage = function() {
  var self = this;
  return self.$q(function(resolve) {
    self.image = self.$document[0].getElementById('visual-searches__image-cropper-image');

    // Due to an issue with Safari 9 and cross origin issue requests, we need to
    // load the image as blob and then manually display the image.
    self.$http({
      method: 'GET',
      url: self.image.getAttribute('data-src'),
      responseType: 'blob'
    }).then(function(response) {
      self.image.onload = resolve;
      self.image.src = self.$window.URL.createObjectURL(response.data);
    });
  });
};

VisualSearchesController.prototype._scrollToTop = function() {
  var elm = this.$document[0].querySelector('#visual-search-heading');
  this.ScrollService.scrollUpTo(elm, SCROLL_DURATION);
};

/**
 * Sets the max container dimensions to the image's natural width & height.
 * @private
 */
VisualSearchesController.prototype._setMaxContainerDimensions = function() {
  var self = this;
  var container = self.$document[0].getElementById('visual-searches__image-cropper');

  container.style.maxWidth = self.image.naturalWidth + 'px';
  container.style.maxHeight = self.image.naturalHeight + 'px';
};

/**
 * Preloads data from script tags on the page and then passes on the data after
 * resolving the Promise.
 *
 * @private
 * @returns {Promise.<Object>}
 */
VisualSearchesController.prototype._preloadData = function() {
  var self = this;
  return self.$q.all([
    self.UserPrefsService.getUserPrefs().then(function(userPrefs) {
      self.showPaidFonts = !!userPrefs.showPaidFonts;
    }),

    self.$q(function(resolve) {
      self.DataService.get('/neue/preloaded_variation_data').then(function(data) {
        self._currentStep = data.step;
        if (data.step != STEPS[0]) {
          self.fonts = data.fonts;
          self.extractedText = data.detectedText;
          self.visualSearchOpaqueId = data.visualSearchOpaqueId;
          self.textSamples = data.textSampleData.textSamples;
          self.textSampleData = data.textSampleData;
        }

        self.showUpgradeActionToggle = self.FilterVariationsService.hasUpgradeFilterOption(self.fonts);
        self.filterFontsByRequiredActions();

        if (UrlService.getSearchParam(USER_TEXT_PARAM)) {
          self._userEnteredText = decodeURIComponent(UrlService.getSearchParam(USER_TEXT_PARAM));
        }

        self._updateExampleText(self._userEnteredText || self.extractedText);
        resolve(data);
      });
      self.DataService.get('/visual_search/i18n').then(function(i18n) {
        self._i18n = i18n;
      });
    })
  ]);
};

/**
 * Sets the current step and updates the url to match the step.
 *
 * @private
 * @param {Boolean} Does the url need to be updated to match the current step?
 */
VisualSearchesController.prototype._setCurrentStep = function(step, updateUrl) {
  this._currentStep = step;

  this.NewrelicService.addPageAction('typekit.render.visual-search.step', { step: step });

  if (updateUrl) {
    UrlService.pushState(null, null, this._getUrlForStep(step));
  }

  // We don't need to persist data for the upload step because that's where the
  // user will upload the image.
  if (step != STEPS[0]) {
    this._storeDataInUrl();
  }
};

/**
 * Sets the loading state for the page.
 * @private
 */
VisualSearchesController.prototype._setLoadingState = function() {
  angular.element(this.$document[0].body).addClass(LOADING_CLASS);
};

/**
 * Display the cropped version of the image.
 * @private
 */
VisualSearchesController.prototype._showCroppedImage = function() {
  var croppedCanvas = this.cropper.getCroppedCanvas();
  this._confirmationCanvas.parentNode.replaceChild(
    croppedCanvas, this._confirmationCanvas);

  this._confirmationCanvas = croppedCanvas;
};

/**
 * Store any data we want to persist between page navigations in the url.
 * @private
 */
VisualSearchesController.prototype._storeDataInUrl = function() {
  var self = this;
  var params = self._getCropperCoordinates();

  if (this._userEnteredText) {
    params[USER_TEXT_PARAM] = encodeURIComponent(this._userEnteredText);
  }

  UrlService.setSearchParams(params);
};

/**
 * Unset the loading state for the page.
 * @private
 */
VisualSearchesController.prototype._unsetLoadingState = function() {
  angular.element(this.$document[0].body).removeClass(LOADING_CLASS);
};

/**
 * Update the current example text.
 *
 * @private
 * @param {String} text
 */
VisualSearchesController.prototype._updateExampleText = function(text) {
  this.exampleText = {
    size: 50,
    value: text || DEFAULT_EXAMPLE_TEXT
  };
};

/**
 * Use the cropper coordinates to update the search results.
 *
 * @private
 * @listens {Event}
 */
VisualSearchesController.prototype._updateResultsForCoordinates = function() {
  var self = this;

  return self.$q(function(resolve) {
    self._setLoadingState();

    self.VisualSearchService.put(self.visualSearchOpaqueId, self._getCropperCoordinates()).then(function(response) {
      self._showCroppedImage();
      self.extractedText = response.data.text;

      self.fonts = response.data.fonts;
      var toggles = {
        upgrade: self.showPaidFonts
      };
      var requiredActions = self.FilterVariationsService.filterRequiredActionsByToggles(self.fonts, toggles);
      self.filteredFonts = self.FilterVariationsService.filterFontsByRequiredActions(self.fonts, requiredActions.filters);

      var data = {fontCount: self.fonts.length } ;
      if (self.fonts.length > 0) {
        data['topResult'] = _.first(_.pluck(self.fonts, 'name'));
      }
      self.NewrelicService.addPageAction('typekit.render.visual-search', data);

      resolve();
      self._unsetLoadingState();
    });
  });
};

module.exports = VisualSearchesController;
