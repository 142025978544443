const SearchParamChangedEvent = require('@adobe-fonts/site/events/SearchParamChangedEvent.js');
const scriptData = require('../../util/script_data.js');
var CUSTOM_SMALL_BREAKPOINT_WIDTH = 500;
var SPECTRUM_SMALL_BREAKPOINT_WIDTH = 768;

/**
 * @ngInject
 */
function MarketplaceHomeController($document, $scope, $window, DataService, FamilyAutocompleteSearchService, NewrelicService, NewrelicClickTrackerService) {
  this.$document = $document;
  this.$window = $window;
  this.DataService = DataService;
  this.FamilyAutocompleteSearchService = FamilyAutocompleteSearchService;
  this.NewrelicService = NewrelicService;
  this._preloadData();

  // Needed for getSmallScreenClass
  $window.addEventListener('resize', function() {
    $scope.$apply();
  });

  // Since this is still included in new the crossbar package due to nav bar
  // adding check to not double count analytics
  if (!scriptData.getData('project_crossbar_data')) {
    NewrelicClickTrackerService.init({page: 'marketplace_homepage'});

    var self = this;
    $window.addEventListener('beforeunload', function () {
      self.NewrelicService.addPageAction('typekit.marketplace_homepage.page_exit');
    });
  }
}

MarketplaceHomeController.prototype._webComponents_initSearchBar = function() {
  var self = this;
  const searchBar = document.querySelector('#homepage-searchbar');
  if (!searchBar) {
    return;
  }

  searchBar.addEventListener(SearchParamChangedEvent.EVENT_NAME, function(event) {
    self.FamilyAutocompleteSearchService.get(event.detail.searchParam).then(function(response) {
      searchBar.results = response.data;
    });
  });
};

/**
 * xs-6 is too small for mobile screen sizes, but xs-12 is too large for the 500-768px range,
 * so this function toggles them.
 *
 * @returns {String}
 */
MarketplaceHomeController.prototype.getSmallScreenClass = function() {
  if (this.$window.innerWidth < CUSTOM_SMALL_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-xs-12';
  }
  if (this.$window.innerWidth < SPECTRUM_SMALL_BREAKPOINT_WIDTH) {
    return 'spectrum-grid-col-xs-6';
  }
  return '';
};

/**
 * Record 'Search by image' clicks for analytics purposes
 *
 */
MarketplaceHomeController.prototype.handleSearchByImageClick = function() {
  this.NewrelicService.addPageAction('typekit.marketplace_homepage.search_by_image_click');
};

MarketplaceHomeController.prototype._preloadData = function() {
  var self = this;
  self.DataService.get('/preloaded_homepage_data').then(function(data) {
    self.browseByClassifications = data.browse_by_classifications;
    self.browseByLanguages = data.browse_by_languages;
    self.browseByTags = data.browse_by_tags;
  });

  this._webComponents_initSearchBar();
};

module.exports = MarketplaceHomeController;
